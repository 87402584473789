import * as React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import Layout from "../layouts/layout";
import Seo from "../components/seo";
import Header from '../components/Header/Header';
import Footer from '../modules/Footer/Footer';

const NotFoundPage = () => {
  const seo = {title: '404: Not found'};

  return (
    <Layout>
      <Header/>
      <Seo seo={seo} />
      <Footer/>
    </Layout>
  )
}

export default NotFoundPage
