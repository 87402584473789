import React from "react"
import ChriateLink from "../../components/Link/Link"
import LinkableIcon from "../../components/LinkableIcon/LinkableIcon"
import LinkedInIcon from "../../images/linkedin.svg"
import InstagramIcon from "../../images/instagram.svg"
import VimeoIcon from "../../images/vimeo.svg"
import ChriateForm from "../../components/ChriateForm/ChriateForm"
import { Link, useStaticQuery, graphql } from "gatsby"

import "./OldFooter.scss"
import "./Footer.scss"

const Footer = React.forwardRef((props, ref) => {
  const year = new Date().getFullYear()
  const { formId, isHome = false } = props
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsThemeOptions {
          themeSettings {
            apacStudios {
              location
              building
              streetAddress
              suburb
              city
              phoneNumber
            }
            europeStudios {
              location
              building
              streetAddress
              suburb
              city
              phoneNumber
            }
            generalEnquiries {
              url
              title
              target
            }
            joinOurVisionaryTeam {
              url
              title
              target
            }
            newProjectEnquiriesOne {
              url
              title
              target
            }
            newProjectEnquiriesTwo {
              url
              title
              target
            }
            vimeoUrl
            linkedinUrl
            instagramUrl
          }
        }
      }
    }
  `)

  const {
    wp: {
      acfOptionsThemeOptions: {
        themeSettings: {
          apacStudios,
          europeStudios,
          generalEnquiries,
          joinOurVisionaryTeam,
          newProjectEnquiriesOne,
          newProjectEnquiriesTwo,
          instagramUrl,
          linkedinUrl,
          vimeoUrl,
        },
      },
    },
  } = data

  return (
    <footer
      ref={ref}
      id="contact"
      className={`footer${isHome ? " footer--home" : ""}`}
      asscroll="true"
    >
      <div className="container-sm v2">
        <div className="row">
          <div className="column w-100 top">
            <h2 className="h6">Contact</h2>
          </div>
          <div className="column w-60">
            {generalEnquiries ? (
              <div className="footer__main-link">
                <h6>GENERAL ENQUIRIES</h6>
                <ChriateLink classes="h2" link={newProjectEnquiriesOne} />
                <ChriateLink classes="h2" link={generalEnquiries} />
              </div>
            ) : (
              ""
            )}

            {joinOurVisionaryTeam ? (
              <div className="footer__main-link">
                <h6>NEW PROJECTS</h6>
                <ChriateLink classes="h2" link={joinOurVisionaryTeam} />
              </div>
            ) : (
              ""
            )}

            {newProjectEnquiriesTwo ? (
              <div className="footer__main-link">
                <h6>JOIN OUR VISIONARY TEAM</h6>
                <ChriateLink classes="h2" link={newProjectEnquiriesTwo} />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="column w-40 vertical-footer__form">
            <h6>NEW PROJECT ENQUIRIES</h6>
            {formId ? <ChriateForm formId={formId} isHome={isHome} /> : ""}
          </div>

          <div className="column w-100 bottom">
            <div className="footer__offices">
              {apacStudios
                ? apacStudios.map((studio, index) => {
                    return (
                      <div key={index} className="footer__offices-studio">
                        <a href={studio.location} target="_blank">
                          {studio.city}
                        </a>
                      </div>
                    )
                  })
                : ""}
              {europeStudios
                ? europeStudios.map((studio, index) => {
                    return (
                      <div key={index} className="footer__offices-studio">
                        <a href={studio.location} target="_blank">
                          {studio.city}
                        </a>
                      </div>
                    )
                  })
                : ""}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column w-100">
            <div className="footer__socials">
              {instagramUrl ? (
                <LinkableIcon
                  url={instagramUrl}
                  target="_blank"
                  icon={<img src="../../images/instagram.svg" />}
                />
              ) : (
                ""
              )}
              {linkedinUrl ? (
                <LinkableIcon
                  url={linkedinUrl}
                  target="_blank"
                  icon={<img src="../../images/linkedin.svg" />}
                />
              ) : (
                ""
              )}
              {vimeoUrl ? (
                <LinkableIcon
                  url={vimeoUrl}
                  target="_blank"
                  icon={<img src="../../images/vimeo.svg" />}
                />
              ) : (
                ""
              )}
            </div>

            <div className="footer__links">
              <Link to="/privacy-policy">Privacy Policy</Link>
              {/* <Link to="/disclaimer">Disclaimer</Link> */}
              <p>&copy; Blank Canvas {year}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
